import React from 'react';
import moment from 'moment';
moment.locale('nb');
import { formatCurrency } from '../../utils/formatCurrency';
import { SizeInfoField } from './SizeInfoField';

export const KomplettSalgsoppgaveText = ({ getObjectData }) => {
  return (
    <>
      {getObjectData && getObjectData.freetext && getObjectData.freetext.aboutTheUnit ? (
        <>
          <h2>Om eiendommen</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.aboutTheUnit.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.vitecOppdragsnummer ? (
        <>
          <h2>Oppdragsnr.</h2>
          <span>{getObjectData.vitecOppdragsnummer}</span>
        </>
      ) : null}
      {getObjectData && getObjectData.costs ? (
        <>
          <h2>Omkostninger.</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.costs
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.rentIncludes ? (
        <>
          <h2>Felleskost. inkluderer</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.rentIncludes.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.beliggenhet ? (
        <>
          <h2>Beliggenhet</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.beliggenhet.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.innhold ? (
        <>
          <h2>Innhold</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.innhold.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.standard ? (
        <>
          <h2>Standard</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.standard.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.descriptionItemRenovated ? (
        <>
          <h2>Modernisering og påkostninger</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.descriptionItemRenovated.replace(
                /\n/g,
                '<br>'
              )
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.plotDescription ? (
        <>
          <h2>Tomten</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.plotDescription.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.parkering ? (
        <>
          <h2>Parkering</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.parkering.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.oppvarming ? (
        <>
          <h2>Oppvarming</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.oppvarming.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.electricalUsage ? (
        <>
          <h2>Strømforbruk</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.electricalUsage.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.energyMarking ? (
        <>
          <h2>Energimerking</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.energyMarking.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {/*getObjectData && getObjectData.bra ? (
        <>
          <h2>Størrelse</h2>
          {getObjectData && getObjectData.area && getObjectData.area.value ? (
            <>
              <span>
                P-rom: {getObjectData.area.value} m<sup>2</sup>
              </span>
              <br />
            </>
          ) : null}
          {getObjectData.bra ? (
            <>
              <span>
                BRA: {getObjectData.bra} m<sup>2</sup>
              </span>
              <br />
            </>
          ) : null}
          {getObjectData.bta ? (
            <>
              <span>
                BTA: {getObjectData.bta} m<sup>2</sup>
              </span>
              <br />
            </>
          ) : null}
        </>
          ) : null*/}
      <SizeInfoField data={getObjectData} />
      {getObjectData.primaryRoomAreaDescription ? (
        <>
          <h2>Arealbeskrivelse</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.primaryRoomAreaDescription
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.valuatorCommentsToArea ? (
        <>
          <h2>Takstmannens kommentar til arealoppmåling:</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.valuatorCommentsToArea.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.nonMeasurableAreas ? (
        <>
          <h2>ALH/GUA</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.nonMeasurableAreas.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.fellesarealerOgRettigheter ? (
        <>
          <h2>Fellesarealer og rettigher til bruk:</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.fellesarealerOgRettigheter.replace(
                /\n/g,
                '<br>'
              )
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.constructionMethod ? (
        <>
          <h2>Byggemåte</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.constructionMethod.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.velforening ? (
        <>
          <h2>Velforening</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.velforening.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}

      {getObjectData && getObjectData.yearlySocietyTax ? (
        <>
          <h2>Årlig velavgift</h2>
          <span>{formatCurrency(getObjectData.yearlySocietyTax)},-</span>
        </>
      ) : null}

      {getObjectData && getObjectData.commonDebt ? (
        <>
          <h2>Andel fellesgjeld</h2>
          <span>
            {formatCurrency(getObjectData.commonDebt)}
            ,-
          </span>
          {getObjectData && getObjectData.shareJointDebtToDate ? (
            <>
              <br />
              <span>
                pr. dato {moment(getObjectData.shareJointDebtToDate).format('DD.MM.YYYY')}
              </span>
            </>
          ) : null}
        </>
      ) : null}
      {getObjectData && getObjectData.jointDebtDescription ? (
        <>
          <h2>Kommentar fellesgjeld</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.jointDebtDescription.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.estateJointOwnershipFraction ? (
        <>
          <h2>Sameiebrøk</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.estateJointOwnershipFraction
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.collectiveAssets ? (
        <>
          <h2>Andel felles formue</h2>
          <span>
            {formatCurrency(getObjectData.collectiveAssets)}
            ,-
          </span>
          {getObjectData && getObjectData.shareJointCapitalDate ? (
            <>
              <br />
              <span>
                pr. dato{' '}
                {moment(getObjectData.shareJointCapitalDate).format('DD.MM.YYYY')}
              </span>
            </>
          ) : null}
        </>
      ) : null}
      {getObjectData && getObjectData.rentIncludes ? (
        <>
          <h2>Felleskost. inkluderer</h2>
          {getObjectData && getObjectData.rentIncludes ? (
            <span>{getObjectData.rentIncludes}</span>
          ) : null}
        </>
      ) : null}

      {getObjectData && getObjectData.joinCostAfterGracePeriod ? (
        <>
          <h2>Felleskostnader etter avdragsfri periode</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.joinCostAfterGracePeriod.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}

      {getObjectData && getObjectData.loanTermsCollectiveDebt ? (
        <>
          <h2>Lånevilkår for fellesgjeld</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.loanTermsCollectiveDebt.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.matrikkel ? (
        <>
          <h2>Betegnelse</h2>
          {getObjectData.matrikkel.knr ? (
            <p>Kommunenummer {getObjectData.matrikkel.knr}</p>
          ) : null}
          {getObjectData.matrikkel.gnr ? (
            <p>Gårdsnummer {getObjectData.matrikkel.gnr}</p>
          ) : null}
          {getObjectData.matrikkel.bnr ? (
            <p>Bruksnummer {getObjectData.matrikkel.bnr}</p>
          ) : null}
          {getObjectData.matrikkel.snr ? (
            <p>Seksjonsnummer {getObjectData.matrikkel.snr}</p>
          ) : null}
          {getObjectData.matrikkel.fnr ? (
            <p>Festenummer {getObjectData.matrikkel.fnr}</p>
          ) : null}
        </>
      ) : null}
      {getObjectData && getObjectData.partName ? (
        <>
          <h2>{getObjectData.ownershipHeading}</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.partName.replace(/\n/g, '<br>')
            }}
          />
          <br />
          {getObjectData.partOrgNumber ? (
            <span>org.nr: {getObjectData.partOrgNumber}</span>
          ) : null}
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.adgangForUtleie ? (
        <>
          <h2>Adgang til utleie</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.adgangForUtleie.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}

      {getObjectData && getObjectData.waterRate ? (
        <>
          <h2>Vannavgift</h2>
          <span>
            {getObjectData && getObjectData.waterRate
              ? formatCurrency(getObjectData.waterRate)
              : ''}
          </span>
        </>
      ) : null}

      {getObjectData && getObjectData.waterRate ? (
        <>
          <h2>Vannavgift år</h2>
          <span>
            {getObjectData && getObjectData.waterRateYear
              ? getObjectData.waterRateYear
              : ''}
          </span>
        </>
      ) : null}

      {getObjectData && getObjectData.waterRate ? (
        <>
          <h2>Info vannavgift</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.waterRateDescription
            }}
          />
        </>
      ) : null}

      {getObjectData && getObjectData.freetext && getObjectData.freetext.veiVannAvlop ? (
        <>
          <h2>Vei, vann og avløp: </h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.veiVannAvlop.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.reguleringsplan ? (
        <>
          <h2>Regulering</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.reguleringsplan.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.legalpant ? (
        <>
          <h2>Rettigheter og forpliktelser</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.legalpant.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.boardApproval ? (
        <>
          <h2>Styregodkjennelse</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.boardApproval.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.takeoverComment ? (
        <>
          <h2>Overtagelse, tilleggsinfo</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.takeoverComment.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.statutes ? (
        <>
          <h2>Vedtekter / husordengsregler</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.statutes.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.residentCommitments ? (
        <>
          <h2>Beboernes forpliktelser</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.residentCommitments.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.accountingBudgeting ? (
        <>
          <h2>Regnskap / budsjett</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.accountingBudgeting.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.preemptive ? (
        <>
          <h2>Forkjøpsrett</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.preemptive.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.ferdigattest ? (
        <>
          <h2>Ferdigattest </h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.ferdigattest.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.dodsbo ? (
        <>
          <h2>Dødsbo</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.dodsbo.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {(getObjectData &&
        getObjectData.freetext &&
        getObjectData.freetext.kommentarKonsesjon) ||
      (getObjectData &&
        getObjectData.freetext &&
        getObjectData.freetext.kommentarOdelsrett) ? (
        <h2>Konsesjon / odel</h2>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.kommentarKonsesjon ? (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.kommentarKonsesjon.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.kommentarOdelsrett ? (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.kommentarOdelsrett.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.kommentarBoDriveplikt ? (
        <>
          <h2>Bo og driveplikt</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.kommentarBoDriveplikt.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.financing ? (
        <>
          <h2>Finansiering</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.financing.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.businessManagers &&
      getObjectData.businessManagers.length ? (
        <>
          <h2>Forretningsfører</h2>
          {getObjectData.businessManagers.map((item, index) => {
            return <span key={`forretningsforer_${index}`}>{item.companyName}</span>;
          })}
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.moneyLaunderingRules ? (
        <>
          <h2>Hvitvasking</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.moneyLaunderingRules.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.insuranceCompany ? (
        <>
          <h2>Forsikringsselskap</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.insuranceCompany.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.insurancePolicy ? (
        <>
          <h2>Forsikringspolicy</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.insurancePolicy.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.homeOwnersInsurance ? (
        <>
          <h2>Boligkjøperforsikring </h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.homeOwnersInsurance.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {/*getObjectData &&
                                  getObjectData.ownAssignmentType ? (
                                    <>
                                      <h2>Oppdragstype</h2>
                                      <span>
                                        {getObjectData.ownAssignmentType ===
                                        'Prosjektmaster'
                                          ? 'Nybygg prosjekt'
                                          : getObjectData.ownAssignmentType}
                                      </span>
                                    </>
                                        ) : null*/}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.adkomst ? (
        <>
          <h2>Adkomst</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.adkomst.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}

      {getObjectData && getObjectData.freetext && getObjectData.freetext.hvitevarer ? (
        <>
          <h2>Hvitevarer</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.hvitevarer.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.bebyggelse ? (
        <>
          <h2>Bebyggelse</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.bebyggelse.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.communityFee ? (
        <>
          <h2>Kommunale avgifter</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.communityFee.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}

      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.propertyTaxComments ? (
        <>
          <h2>Info eiendomsskatt</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.propertyTaxComments.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.offentligKommunikasjon ? (
        <>
          <h2>Offentlig kommunikasjon</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.offentligKommunikasjon.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.skolekrets ? (
        <>
          <h2>Skolekrets</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.skolekrets.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}

      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.barnehageSkoleFritid ? (
        <>
          <h2>Barnehage, skole og fritid</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.barnehageSkoleFritid.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}

      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.personalDataAct ? (
        <>
          <h2>Personopplysningsloven</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.personalDataAct.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.servitutterErklaeringer ? (
        <>
          <h2>Servitutter</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.servitutterErklaeringer.replace(
                /\n/g,
                '<br>'
              )
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.meglersVederlag ? (
        <>
          <h2>Vederlag</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.meglersVederlag.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.diverse ? (
        <>
          <h2>Diverse</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.diverse.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.guaranteeFund ? (
        <>
          <h2>Sikringsordning f.g</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.guaranteeFund.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.preferences && getObjectData.preferences.length ? (
        <>
          <h2>Fasiliteter</h2>
          <ul>
            {getObjectData.preferences.map((item, index) => {
              let label = item;
              if (item.match(/Garasje/)) label = 'Garasje / P-plass';
              return <li key={`preferences_${index}`}>{label}</li>;
            })}
          </ul>
        </>
      ) : null}
      {getObjectData && getObjectData.ligningsverdi ? (
        <>
          <h2>Ligningsverdi</h2>
          <span>
            {getObjectData && getObjectData.ligningsverdi
              ? formatCurrency(getObjectData.ligningsverdi)
              : null}
            ,- pr. år {getObjectData.ligningsverdiYear}.
          </span>
          <br />
        </>
      ) : null}
      {getObjectData && getObjectData.ligningsverdi ? (
        <>
          <h2>Formuesverdi</h2>
          {getObjectData && getObjectData.ligningsverdiSekundaer ? (
            <span>
              {getObjectData && getObjectData.ligningsverdiSekundaer
                ? formatCurrency(getObjectData.ligningsverdiSekundaer)
                : null}
              ,- i sekundær formuesverdi.
            </span>
          ) : null}
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.accountingBudgeting ? (
        <>
          <h2>Sameiets regnskap</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.accountingBudgeting
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.showingNote ? (
        <>
          <h2>Visning</h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.showingNote
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.owners && getObjectData.owners.length ? (
        <>
          <h2>Eier(e)</h2>
          {getObjectData.owners.map((item, index) => {
            return (
              <React.Fragment key={`owner_${index}`}>
                <span>
                  {item.firstName} {item.lastName}
                </span>
                <br />
              </React.Fragment>
            );
          })}
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.biddingInfo ? (
        <>
          <h2>Budgivning </h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.biddingInfo.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.paymentConditions ? (
        <>
          <h2>Betalingsbetingelser </h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.paymentConditions.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.freetext &&
      getObjectData.freetext.eierskifteforsikring ? (
        <>
          <h2>Eierskifteforsikring </h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.eierskifteforsikring.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData && getObjectData.freetext && getObjectData.freetext.vedlegg ? (
        <>
          <h2>Vedlegg </h2>
          <span
            dangerouslySetInnerHTML={{
              __html: getObjectData.freetext.vedlegg.replace(/\n/g, '<br>')
            }}
          />
        </>
      ) : null}
      {getObjectData &&
      getObjectData.ansvarligMegler &&
      getObjectData.ansvarligMegler.name ? (
        <>
          <h2>Ansvarlig megler</h2>
          <span>{getObjectData.ansvarligMegler.name}</span>
        </>
      ) : null}
      <div style={{ marginTop: '40px' }}>
        <h1 style={{ color: '#000', fontSize: '25px' }}>Vilkår</h1>
        {getObjectData && getObjectData.terms ? (
          <div dangerouslySetInnerHTML={{ __html: getObjectData.terms }} />
        ) : null}
      </div>
    </>
  );
};
