import React, { Component } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withApollo, compose, graphql, Query } from 'react-apollo';
import { TOGGLE_MENU, TOGGLE_SEARCH } from './client/__graphql__/mutations';
import { Page } from './common/components/layout/Page';

import ObjectPage from './common/components/containers/Object';
import NoMatch from './common/components/containers/NoMatch';
import { GlobalStyles } from './client/__config__/globalStyles';
import {
  GET_ESTATEID_BY_FINNKODE,
  GET_FINNCODE_BY_ESTATEID,
} from './client/__graphql__/queries';

class App extends Component {
  componentDidMount() {
    const { client } = this.props;
    if (typeof window !== 'undefined') {
      client.writeData({
        data: {
          view: {
            __typename: 'View',
            size: {
              __typename: 'Size',
              width: window.innerWidth,
              height: window.innerHeight,
              scrollTop: window.scrollY,
            },
          },
        },
      });
      this.resizeListener = window.addEventListener('resize', (d) => {
        client.writeData({
          data: {
            view: {
              __typename: 'View',
              size: {
                __typename: 'Size',
                width: window.innerWidth,
                height: window.innerHeight,
              },
            },
          },
        });
      });
      this.scrollListener = window.addEventListener('scroll', () => {
        client.writeData({
          data: {
            view: {
              __typename: 'View',
              size: {
                __typename: 'Size',
                scrollTop: window.scrollY,
              },
            },
          },
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { location, toggleMenu, toggleSearch } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      toggleMenu({ variables: { toggle: false } });
      toggleSearch({ variables: { toggle: false } });
    }
  }

  render() {
    return (
      <Page>
        <GlobalStyles />
        <Switch>
          <Route
            path="/:p1/:p2?/:p3?"
            render={(props) => {
              if (
                props.match.params &&
                props.match.params.p1 &&
                props.match.params.p1.match(/[0-9]/) &&
                !props.match.params.p1.match(/[A-Z]+[0-9.]+/) &&
                !props.match.params.p1.match(
                  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/i
                )
              ) {
                return (
                  <Query
                    query={GET_ESTATEID_BY_FINNKODE}
                    variables={{ finnkode: props.match.params.p1 }}
                  >
                    {({ data, loading, error }) => {
                      if (loading) {
                        return null;
                      }

                      if (
                        !loading &&
                        data.getEstateIdByFinncode &&
                        data.getEstateIdByFinncode.estateId
                      ) {
                        const subpage =
                          props.match.params && props.match.params.p2
                            ? `/${props.match.params.p2}`
                            : '';
                        return (
                          <Redirect
                            to={`/eiendom/${data.getEstateIdByFinncode.estateId}${subpage}`}
                          />
                        );
                      }
                    }}
                  </Query>
                );
              }
              if (
                props.match.params &&
                props.match.params.p2 &&
                props.match.params.p2.match(/[0-9]/) &&
                !props.match.params.p2.match(/[A-Z]+[0-9]+/) &&
                !props.match.params.p2.match(
                  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/i
                )
              ) {
                return (
                  <Query
                    query={GET_ESTATEID_BY_FINNKODE}
                    variables={{ finnkode: props.match.params.p2 }}
                  >
                    {({ data, loading, error }) => {
                      if (loading) {
                        return null;
                      }

                      if (
                        !loading &&
                        data.getEstateIdByFinncode &&
                        data.getEstateIdByFinncode.estateId
                      ) {
                        const subpage =
                          props.match.params && props.match.params.p3
                            ? `/${props.match.params.p3}`
                            : '';
                        return (
                          <Redirect
                            to={`/eiendom/${data.getEstateIdByFinncode.estateId}${subpage}`}
                          />
                        );
                      }
                    }}
                  </Query>
                );
              }

              if (
                props.match.params.p1 &&
                props.match.params.p1.match(
                  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/i
                ) &&
                (props.match.params.p2 === 'detaljer' ||
                  props.match.params.p2 === 'bestillsalgsoppgave')
              ) {
                return <ObjectPage sguid={props.match.params.p1} {...props} />;
              }

              if (
                props.match.params.p2 &&
                props.match.params.p2.match(/[A-Z]+[0-9.]+/)
              ) {
                return <ObjectPage sguid={props.match.params.p2} {...props} />;
              } else if (
                props.match.params.p1 &&
                props.match.params.p1.match(
                  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/i
                ) &&
                !props.match.params.p2
              ) {
                return <ObjectPage sguid={props.match.params.p1} {...props} />;
              } else if (
                props.match.params.p2 &&
                props.match.params.p2.match(
                  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/i
                )
              ) {
                return <ObjectPage sguid={props.match.params.p2} {...props} />;
              } else {
                return <Redirect to="/feil" />;
              }
            }}
          />
          <Route exact path="/finner-ikke-side" component={NoMatch} />
        </Switch>
      </Page>
    );
  }
}

export default compose(
  withApollo,
  withRouter,
  graphql(TOGGLE_MENU, { name: 'toggleMenu' }),
  graphql(TOGGLE_SEARCH, { name: 'toggleSearch' })
)(App);
