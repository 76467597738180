import gql from 'graphql-tag';

export const SEARCH = gql`
  query Search($input: SearchInput) {
    search(input: $input) {
      hits
      result
    }
  }
`;

export const GET_DS_CURRENT = gql`
  query GetCurrentDsUrl($input: EstateIdInput) {
    getCurrentDsUrl(input: $input) {
      url
      type
    }
  }
`;

export const GET_ATTACHMENTS = gql`
  query GetAttachment($input: String) {
    getAttachment(input: $input) {
      documentId
      url
      head
    }
  }
`;

export const GET_SALGSOPPGAVE = gql`
  query Attachment($input: String) {
    getCurrentSalgsoppgave(input: $input) {
      documentId
      url
      head
      description
      extension
      docType
    }
  }
`;

export const GET_TERMS_TEXT = gql`
  query Terms($input: String) {
    getTermText(input: $input)
  }
`;

export const GET_ESTATEID_BY_FINNKODE = gql`
  query GetEstateIdByFinncode($finnkode: String) {
    getEstateIdByFinncode(finnkode: $finnkode) {
      estateId
    }
  }
`;

export const GET_OBJECT_DATA = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      brand {
        name
      }
      terms
      estateId
      isKbbl
      isAbbl
      isNbbo
      isStorheia
      isWhitelabel
      whitelabelOfficeUrl
      oppdragsnummer
      vitecOppdragsnummer
      title
      description {
        header
        text
      }
      businessManagers {
        companyName
      }
      attachment
      downloadSalgsoppgave
      valuation
      street
      city
      zip
      type
      dyrehold
      nabolagprofil
      smartBoligAnalyse
      guaranteeFund
      estimatedValue
      loanFare
      rentIncludes
      rentPrMonth
      rentPerWeek
      rentPerYear
      rentPerMonth
      rentPerDay
      rentDeposit
      owners {
        firstName
        lastName
      }
      liveAndManagementDuty
      facilities
      preferences
      showingNote
      ownAssignmentType
      ownership
      ownershipHeading
      taxValue
      ligningsverdi
      ligningsverdiYear
      waterRate
      waterRateDescription
      waterRateYear
      yearlySocietyTax
      ownPart
      propertyTaxAmount
      propertyTaxYear
      ligningsverdiSekundaer
      collectiveAssets
      interestGracePeriod
      communityTaxes
      propertyUnitNumber
      cadastralUnitNumber
      area {
        value
        from
        to
      }
      size {
        enclosedBalconyArea
        enclosedBalconyAreaTo
        grossArea
        grossAreaTo
        usableArea
        usableAreaTo
        usableExternalArea
        usableExternalAreaTo
        usableInternalArea
        usableInternalAreaTo
        openArea
        openAreaTo
        primaryArea
        primaryAreaTo
      }
      plotOwned
      bra
      bta
      price {
        value
        from
        to
      }
      totalPrice
      monthCosts
      purchaseCostsAmount
      commonDebt
      sold
      level
      rooms
      bedrooms
      propertyArea
      constructionYear
      costs
      finncode
      energyLabel
      energyLabelColor
      lat
      lng
      agents {
        name
        firstName
        lastName
        title
        email
        phone
        image
        hidden
        office {
          name
          href
        }
      }
      ansvarligMegler {
        name
      }
      bidLink
      videoLink
      viewing {
        from
        to
        day
        month
        timeFrom
        timeTo
        dayName
      }
      prospect
      salgsoppgave
      visible
      plotDescription
      primaryRoomAreaDescription
      loanTermsCollectiveDebt
      partName
      partOrgNumber
      boardApproval
      takeoverComment
      preemptive
      matrikkel {
        knr
        gnr
        snr
        bnr
        fnr
      }
      legalName
      accountingBudgeting
      statutes
      residentCommitments
      jointDebtDescription
      joinCostAfterGracePeriod
      shareJointCapitalDate
      shareJointDebtToDate
      estateJointOwnershipFraction
      dsUrl {
        type
        url
      }
      freetext {
        adkomst
        bebyggelse
        barnehageSkoleFritid
        skolekrets
        offentligKommunikasjon
        innhold
        standard
        renovert
        hvitevarer
        tekniskeInstallasjoner
        parkering
        diverse
        ferdigattest
        veiVannAvlop
        reguleringsplan
        adgangForUtleie
        servitutterErklaeringer
        legalpant
        kommentarKonsesjon
        kommentarBoDriveplikt
        kommentarOdelsrett
        dodsbo
        sentraleLoverAsIs
        eierskifteforsikring
        vedlegg
        beliggenhet
        oppvarming
        velforening
        servitutter
        meglersVederlag
        biddingInfo
        communityFee
        constructionMethod
        electricalUsage
        energyMarking
        financing
        homeOwnersInsurance
        insuranceCompany
        insurancePolicy
        moneyLaunderingRules
        fulfillmentBond
        paymentConditions
        personalDataAct
        purchaseContractAttachment
        purchaseCosts
        sellersDeclaration
        descriptionItemRenovated
        propertyTaxComments
        aboutTheUnit
        redemptionTerms
        regulationOfLeaseExpense
        leasePeriod
        localAreaName
        contractPosition
        contractPositionGuarantees
        originalAdvanceAgreement
        kitchenDeliveryDescription
        bathroomDeliveryDescription
        livingroomDescription
        additionalDeliveryInfo
        importantInfo
        outsideAreaType
        velforening
        nonMeasurableAreas
        valuatorCommentsToArea
        fellesarealerOgRettigheter
      }
    }
    objectImages(input: $input) {
      images {
        description
        w2000
      }
    }
  }
`;

export const GET_PRINTABLE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      salgsoppgave
    }
  }
`;

export const GET_VIEWINGS = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      viewing {
        from
        to
        day
        month
        timeFrom
        timeTo
        dayName
      }
    }
  }
`;

export const GET_OFFICE = gql`
  query GetOffice($href: String) {
    office(href: $href) {
      name
      address
      zipcode
      district
      shortname
      description
      about
      phone
      fax
      email
      href
      lng
      lat
      emailValuation
      emailContactform
      facebookPage
      image
    }
  }
`;

export const MAP_LOCATION_CACHE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      lat
      lng
    }
  }
`;

export const NABOLAG_CACHE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      nabolagprofil
    }
  }
`;

export const SMARTBOLIGANALYSE_CACHE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      smartBoligAnalyse
    }
  }
`;

export const DESCRIPTION_CACHE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      terms
      estateId
      vitecOppdragsnummer
      plotDescription
      primaryRoomAreaDescription
      loanTermsCollectiveDebt
      partName
      partOrgNumber
      boardApproval
      takeoverComment
      preemptive
      estimatedValue
      loanFare
      ownPart
      propertyTaxAmount
      propertyTaxYear
      guaranteeFund
      rentIncludes
      rentPrMonth
      rentPerWeek
      rentPerYear
      rentPerMonth
      rentPerDay
      rentDeposit
      ligningsverdi
      ligningsverdiYear
      ligningsverdiSekundaer
      collectiveAssets
      interestGracePeriod
      dyrehold
      liveAndManagementDuty
      facilities
      attachment
      downloadSalgsoppgave
      preferences
      costs
      ansvarligMegler {
        name
      }
      showingNote
      businessManagers {
        companyName
      }
      owners {
        firstName
        lastName
      }
      ownAssignmentType
      matrikkel {
        knr
        gnr
        snr
        bnr
        fnr
      }
      area {
        value
        from
        to
      }
      size {
        enclosedBalconyArea
        enclosedBalconyAreaTo
        grossArea
        grossAreaTo
        usableArea
        usableAreaTo
        usableExternalArea
        usableExternalAreaTo
        usableInternalArea
        usableInternalAreaTo
        openArea
        openAreaTo
        primaryArea
        primaryAreaTo
      }
      plotOwned
      bra
      bta
      legalName
      freetext {
        adkomst
        bebyggelse
        barnehageSkoleFritid
        skolekrets
        offentligKommunikasjon
        innhold
        standard
        renovert
        hvitevarer
        tekniskeInstallasjoner
        parkering
        diverse
        ferdigattest
        veiVannAvlop
        reguleringsplan
        adgangForUtleie
        servitutterErklaeringer
        legalpant
        kommentarKonsesjon
        kommentarBoDriveplikt
        kommentarOdelsrett
        dodsbo
        sentraleLoverAsIs
        eierskifteforsikring
        vedlegg
        beliggenhet
        oppvarming
        velforening
        servitutter
        meglersVederlag
        biddingInfo
        communityFee
        constructionMethod
        electricalUsage
        energyMarking
        financing
        homeOwnersInsurance
        insuranceCompany
        insurancePolicy
        moneyLaunderingRules
        fulfillmentBond
        paymentConditions
        personalDataAct
        purchaseContractAttachment
        purchaseCosts
        sellersDeclaration
        descriptionItemRenovated
        propertyTaxComments
        aboutTheUnit
        redemptionTerms
        regulationOfLeaseExpense
        leasePeriod
        localAreaName
        contractPosition
        contractPositionGuarantees
        originalAdvanceAgreement
        kitchenDeliveryDescription
        bathroomDeliveryDescription
        livingroomDescription
        additionalDeliveryInfo
        importantInfo
        outsideAreaType
        nonMeasurableAreas
        valuatorCommentsToArea
        fellesarealerOgRettigheter
      }
      prospect
      dsUrl {
        type
        url
      }
    }
  }
`;

export const AGENT_CACHE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      isWhitelabel @client
      agents @client {
        name
        firstName
        lastName
        title
        email
        phone
        image
        hidden
        office {
          name
          href
        }
      }
    }
  }
`;

export const GET_SINGLE_OBJECT_IMAGES = gql`
  query objectImages($input: GetObject) {
    objectImages(input: $input) {
      images {
        description
        w2000
      }
    }
  }
`;

export const BID_LINK_CACHE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      bidLink
    }
  }
`;

export const SOLD_CACHE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      sold
    }
  }
`;

export const KBBL_CACHE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      isKbbl @client
    }
  }
`;

export const MODAL_CACHE = gql`
  query {
    objectPage @client {
      modal {
        show
        type
        scroll
      }
    }
  }
`;

export const VALUATION_LINK_CACHE = gql`
  query getObjectData($input: GetObject) {
    getObjectData(input: $input) {
      valuation
    }
  }
`;

export const WHITELABEL_CHECK = gql`
  query getWhiteLabelInfo($input: GetObject) {
    getWhiteLabelInfo(input: $input) {
      isWhitelabel
      redirectUrl
    }
  }
`;

export const CACHE = gql`
  query {
    objectPage {
      externalLink
      infotab {
        show
        sectionOpen
        currentSection
      }
      gallery {
        show
        galleryOpen
        showVideo
        loading
      }
    }
    common {
      menu {
        show
        showSearch
      }
    }
    view {
      size {
        width
        height
        scrollTop
      }
    }
  }
`;
