import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Query, compose, graphql, withApollo } from 'react-apollo';
import {
  CACHE,
  GET_OBJECT_DATA,
  WHITELABEL_CHECK
} from '../../../client/__graphql__/queries';
import { TOGGLE_GALLERY } from '../../../client/__graphql__/mutations';
import { formatCurrency } from '../../utils/formatCurrency';

import { Meta } from '../utils/Meta';
import Header from '../layout/Header';
import MobileMenu from '../ui/MobileMenu';
import Slideshow from '../ui/Slideshow';
import InfoTab from '../ui/Infotab';
import Viewing from '../ui/Viewing';
import { InfoRow } from '../ui/Info';
import { InfoItem } from '../ui/InfoItem';
import LinkMobileItem from '../ui/LinkMobileItem';
import Modal from '../ui/Modal';
import { Sold } from '../ui/Sold';
import { VideoButton } from '../ui/VideoButton';
import { getSizeData } from '../utils/getSizeData';

const InfoRow2 = styled(InfoRow)`
  margin-top: 30px;
  justify-content: center;
`;

const PageTop = styled.div`
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: ${(props) => (props.noPointer ? 'none' : 'auto')};
  z-index: 9998;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const PageTopContent = styled.div`
  width: 100%;
`;

const SlideshowGalleryStart = styled.div`
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 40px;
  bottom: 0;
  margin: auto;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: solid 2px white;
  font-weight: bold;
  color: white;
  z-index: 1;
  user-select: none;
  opacity: ${(props) => (props.show === true && props.isloading === false ? 1 : 0)};
  transform: ${(props) =>
    props.show === true && props.isloading === false
      ? 'translate3d(0, 0, 0)'
      : 'translate3d(-10px, 0, 0)'};
  pointer-events: ${(props) => (props.show ? 'auto' : 'none')};

  &:hover {
    cursor: pointer;
  }
`;

const MobileTop = styled.div`
  display: none;
  width: 100%;
  padding: 60px 40px;
  background: #fff;
  text-align: center;

  h1 {
    margin: 0 0 20px 0;
    font-weight: 400;
  }

  h2 {
    margin: 0;
    font-weight: 300;
  }

  h1,
  h2 {
    color: black;
  }

  p {
    text-align: center;
  }

  @media screen and (max-width: 960px) {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`;

const MobileContent = styled.div`
  display: none;
  width: 100%;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-flow: column;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: auto;
  float: left;
  border-top: solid 1px rgb(235, 235, 235);
  border-bottom: solid 1px rgb(235, 235, 235);
  background: rgb(250, 250, 250);
`;

const InfoMobileItem = styled.div`
  width: ${(props) => (props.full ? '100%' : 'calc(100% / 2)')};
  padding: 20px;
  font-size: 16px;
  border-right: solid 1px rgb(235, 235, 235);
  border-bottom: solid 1px rgb(235, 235, 235);
  background: rgb(250, 250, 250);
  color: #000;

  &:nth-of-type(${(props) => (props.full || props.costs ? 'odd' : 'even')}) {
    border-right: none;
  }

  &:nth-last-child(-n + 2) {
    border-bottom: none;
  }

  .costs {
    font-size: 18px;
    font-weight: 300;
  }

  span {
    display: block;
    &:first-child {
      font-weight: 500;
      font-size: 14px;
    }
    &:last-child {
      font-weight: 300;
      font-size: 18px;
      color: #000;
    }
  }

  a {
    color: #000;
    text-decoration: none;
    font-weight: 300;
    font-weight: 18px;
  }
  a:active {
    color: #000;
  }
`;

class ObjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      itemOpen: ''
    };
  }

  async componentDidMount() {
    const { location, client, match, sguid } = this.props;
    if (sguid) {
      if (typeof window !== 'undefined' && window.location && window.location.hostname) {
        if (window.location.hostname.includes('garanti.no')) {
          //check if whitelabel
          const {
            data: {
              getWhiteLabelInfo: { isWhitelabel, redirectUrl }
            }
          } = await client.query({
            query: WHITELABEL_CHECK,
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                sguid: this.props.sguid,
                path:
                  match && match.params && match.params.p3 ? match.params.p3 : undefined
              }
            }
          });
          if (isWhitelabel && redirectUrl) {
            window.location.href = redirectUrl;
          }
        }
      }
    }

    if (
      match &&
      match.params &&
      ((match.params.p2 && match.params.p2 === 'detaljer') ||
        (match.params.p3 && match.params.p3 === 'detaljer'))
    ) {
      if (typeof window !== 'undefined') {
        client.writeData({
          data: {
            objectPage: {
              __typename: 'ObjectPage',
              externalLink: true,
              infotab: {
                __typename: 'InfoTab',
                show: true
              },
              modal: {
                __typename: 'Modal',
                show: true,
                type: 'sales-text',
                scroll: 0
              }
            }
          }
        });
      }

      if (typeof document !== 'undefined' && window.innerWidth > 960) {
        document.body.classList.add('no-scroll');
      }
    }

    if (
      match &&
      match.params &&
      ((match.params.p2 && match.params.p2 === 'bestillsalgsoppgave') ||
        (match.params.p3 && match.params.p3 === 'bestillsalgsoppgave'))
    ) {
      if (typeof window !== 'undefined') {
        client.writeData({
          data: {
            objectPage: {
              __typename: 'ObjectPage',
              externalLink: true,
              infotab: {
                __typename: 'InfoTab',
                show: true,
                sectionOpen: true,
                currentSection: 'printable'
              },
              modal: {
                __typename: 'Modal',
                show: true,
                type: 'printable',
                scroll: 0
              }
            }
          }
        });
      }

      if (typeof document !== 'undefined' && window.innerWidth > 960) {
        document.body.classList.add('no-scroll');
      }
    }
  }

  _handleAccordionClick(name) {
    if (this.state.itemOpen === name) {
      this.setState({
        open: false,
        itemOpen: ''
      });
    } else {
      this.setState({
        open: true,
        itemOpen: name
      });
    }
  }

  _energyLabelColorLabel(color) {
    switch (color) {
      case 'green':
        return 'Grønn';
      case 'lightGreen':
        return 'Lys grønn';
      case 'yellow':
        return 'Gul';
      case 'orange':
        return 'Oransje';
      case 'red':
        return 'Rød';
      default:
        return '';
    }
  }

  _getPriceType(data) {
    if (data && data.ownAssignmentType === 'Utleie bolig') {
      let label = '';
      let price = 0;
      if (data.rentPerMonth) {
        label = 'Månedsleie';
        price = data.rentPerMonth;
      }
      if (data.rentPerWeek) {
        label = 'Ukesleie';
        price = data.rentPerWeek;
      }
      if (data.rentPerYear) {
        label = 'Årsleie';
        price = data.rentPerYear;
      }
      if (data.rentPerDay) {
        label = 'Dagsleie';
        price = data.rentPerDay;
      }

      return <InfoItem label={label} value={price ? `${formatCurrency(price)},-` : 0} />;
    } else {
      return (
        <InfoItem
          label="Prisantydning"
          value={
            data && data.price && data.price.value ? (
              `${data.price.value ? formatCurrency(data.price.value) : 0},-`
            ) : data &&
              data.price &&
              data.price.from === null &&
              data.price.to === null &&
              data.price.value === null ? (
              <div>-</div>
            ) : (
              <Fragment>
                <div>
                  fra{' '}
                  {data && data.price && data.price.from
                    ? formatCurrency(data.price.from)
                    : 0}
                  ,-{' '}
                </div>
                <div>
                  til{' '}
                  {data && data.price && data.price.to
                    ? formatCurrency(data.price.to)
                    : 0}
                  ,-{' '}
                </div>
              </Fragment>
            )
          }
        />
      );
    }
  }

  _getTopSize(data) {
    if (!data) return null;
    const size = getSizeData(data);
    if (!size) return null;
    const { type, from, to } = size;
    if (!type || !from) return null;
    const title = type === 'usableInternal' ? 'BRA-I' : 'P-rom';
    if (to) {
      return (
        <InfoItem label={title}>
          <div>
            fra {from}m<sup>2</sup>
          </div>
          <div>
            til {to}m<sup>2</sup>
          </div>
        </InfoItem>
      );
    }
    return (
      <InfoItem label={title}>
        {from}m<sup>2</sup>
      </InfoItem>
    );
  }

  _getMobileSize = (data) => {
    if (!data) return null;
    const size = getSizeData(data);
    if (!size) return null;
    const { type, from, to } = size;
    if (!type || !from) return null;
    const title = type === 'usableInternal' ? 'BRA-I' : 'Primærrom';
    if (to) {
      return (
        <InfoMobileItem costs={data.costs}>
          <span>{title}:</span>
          <span>
            {from}m<sup>2</sup> - {to}m<sup>2</sup>
          </span>
        </InfoMobileItem>
      );
    }
    return (
      <InfoMobileItem costs={data.costs}>
        <span>{title}:</span>
        <span>
          {from}m<sup>2</sup>
        </span>
      </InfoMobileItem>
    );
  };

  render() {
    let { sguid, match, history, location, client } = this.props;

    return (
      <Query
        query={GET_OBJECT_DATA}
        variables={{
          input: {
            sguid: sguid,
            path: match.params.p2
          }
        }}
        fetchPolicy="network-only"
      >
        {({ data: { getObjectData, objectImages }, loading, error }) => {
          return (
            <Query query={CACHE} fetchPolicy="cache-only">
              {({ data: { view, objectPage, common } }) => {
                if (getObjectData && getObjectData.visible === false) {
                  return <Redirect to="/finner-ikke-side" />;
                }

                let galleryShow =
                  objectPage && objectPage.gallery ? objectPage.gallery.show : false;
                let menuOpen = common && common.menu && common.menu.show ? true : false;

                if (getObjectData || loading) {
                  return (
                    <Fragment>
                      <Meta
                        brandName={
                          getObjectData && getObjectData.brand && getObjectData.brand.name
                        }
                        title={getObjectData && getObjectData.title}
                        type={getObjectData && getObjectData.type}
                        price={getObjectData && getObjectData.price}
                        description={getObjectData && getObjectData.title}
                        url={match.params}
                        image={
                          objectImages &&
                          objectImages.images &&
                          objectImages.images[0] &&
                          objectImages.images[0].w2000
                        }
                      />
                      <Header
                        data={getObjectData && getObjectData}
                        currentTheme="white"
                      />
                      <MobileMenu
                        show={!galleryShow && menuOpen}
                        viewHeight={view && view.size && view.size.height}
                        kbbl={getObjectData && getObjectData.isKbbl}
                      />
                      <PageTop
                        noPointer={
                          objectPage && objectPage.gallery && objectPage.gallery.show
                            ? true
                            : false
                        }
                      >
                        <PageTopContent
                          hide={
                            objectPage && objectPage.gallery && objectPage.gallery.show
                              ? true
                              : false
                          }
                        >
                          <Viewing
                            className="show-desktop"
                            galleryShow={
                              objectPage && objectPage.gallery && objectPage.gallery.show
                            }
                            sguid={sguid}
                          />
                          <InfoTab
                            history={history}
                            match={this.props.match}
                            location={location}
                            externalLink={objectPage && objectPage.externalLink}
                            cache={objectPage}
                            sguid={sguid}
                            background="lightGray"
                            galleryShow={
                              objectPage && objectPage.gallery && objectPage.gallery.show
                            }
                            showAdditionalContent={
                              objectPage && objectPage.infotab
                                ? objectPage.infotab.show
                                : false
                            }
                            show={
                              objectPage &&
                              objectPage.gallery &&
                              objectPage.gallery.show &&
                              !objectPage.gallery.loading
                                ? true
                                : false
                            }
                          />
                          <SlideshowGalleryStart
                            show={
                              objectPage && objectPage.gallery && objectPage.gallery.show
                                ? false
                                : true
                            }
                            isloading={
                              objectPage &&
                              objectPage.gallery &&
                              objectPage.gallery.loading
                                ? true
                                : false
                            }
                            onClick={() => this.props.toggleGallery()}
                          >
                            Galleri
                          </SlideshowGalleryStart>

                          {!loading &&
                          objectPage &&
                          objectPage.gallery &&
                          !objectPage.gallery.show &&
                          getObjectData &&
                          getObjectData.videoLink ? (
                            <VideoButton
                              onClick={() => {
                                client.writeData({
                                  data: {
                                    objectPage: {
                                      __typename: 'ObjectPage',
                                      gallery: {
                                        __typename: 'Gallery',
                                        galleryOpen: true,
                                        show: true,
                                        showVideo: true
                                      }
                                    }
                                  }
                                });
                              }}
                            />
                          ) : null}
                        </PageTopContent>
                      </PageTop>
                      <Slideshow
                        show={
                          objectPage && objectPage.gallery && objectPage.gallery.show
                            ? true
                            : false
                        }
                        sguid={sguid}
                        onGalleryOpen={() => this.props.toggleGallery()}
                        videoLink={getObjectData && getObjectData.videoLink}
                        videoButton={null}
                      />
                      {objectPage && objectPage.gallery && !objectPage.gallery.show ? (
                        <Viewing className="show-mobile" sguid={sguid} />
                      ) : null}
                      <MobileTop>
                        <h1>{getObjectData && getObjectData.city}</h1>
                        <h2>{getObjectData && getObjectData.street}</h2>
                        <h2>
                          {getObjectData && getObjectData.zip}{' '}
                          {getObjectData && getObjectData.city}
                        </h2>
                        {getObjectData &&
                        getObjectData.ownAssignmentType &&
                        getObjectData.ownAssignmentType === 'Utleie bolig' ? (
                          getObjectData && getObjectData.sold ? (
                            <Sold mobile="true">Utleid</Sold>
                          ) : null
                        ) : getObjectData && getObjectData.sold ? (
                          <Sold mobile="true">Solgt</Sold>
                        ) : null}

                        <InfoRow2>
                          {this._getPriceType(getObjectData)}
                          {this._getTopSize(getObjectData)}
                          {/*getObjectData &&
                          getObjectData.area &&
                          getObjectData.area.value ? (
                            <InfoItem label="P-rom">
                              {getObjectData.area.value}m<sup>2</sup>
                            </InfoItem>
                          ) : null}
                          {getObjectData &&
                          getObjectData.area &&
                          getObjectData.area.from ? (
                            <InfoItem label="P-rom">
                              <div>
                                fra {getObjectData.area.from}m<sup>2</sup>
                              </div>
                              <div>
                                til {getObjectData.area.to}m<sup>2</sup>
                              </div>
                            </InfoItem>
                          ) : null*/}
                          <InfoItem
                            label="Type"
                            value={getObjectData && getObjectData.type}
                          />
                        </InfoRow2>
                        <p>{getObjectData && getObjectData.title}</p>
                      </MobileTop>
                      <MobileContent>
                        <InfoWrapper>
                          {getObjectData && getObjectData.purchaseCostsAmount ? (
                            <InfoMobileItem costs={getObjectData.purchaseCostsAmount}>
                              <span>Omkostninger:</span>
                              <span>
                                {getObjectData.purchaseCostsAmount
                                  ? formatCurrency(getObjectData.purchaseCostsAmount)
                                  : 0}
                                ,-
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData && getObjectData.totalPrice ? (
                            <InfoMobileItem costs={getObjectData.costs}>
                              <span>Totalpris inkl. omk:</span>
                              <span>
                                {getObjectData.totalPrice
                                  ? formatCurrency(getObjectData.totalPrice)
                                  : 0}
                                ,-
                              </span>
                            </InfoMobileItem>
                          ) : null}

                          {getObjectData && getObjectData.taxValue ? (
                            <InfoMobileItem costs={getObjectData.costs}>
                              <span>Ligningsverdi:</span>
                              <span>
                                {getObjectData.taxValue
                                  ? formatCurrency(getObjectData.taxValue)
                                  : 0}
                                ,- pr. år {getObjectData.ligningsverdiYear}.
                              </span>
                            </InfoMobileItem>
                          ) : null}

                          {getObjectData && getObjectData.taxValue ? (
                            <InfoMobileItem costs={getObjectData.costs}>
                              <span>Formuesverdi:</span>
                              <span>
                                {getObjectData && getObjectData.ligningsverdiSekundaer ? (
                                  <span>
                                    {getObjectData &&
                                    getObjectData.ligningsverdiSekundaer > 0
                                      ? formatCurrency(
                                          getObjectData.ligningsverdiSekundaer
                                        )
                                      : null}
                                    ,- i sekundær formuesverdi.
                                  </span>
                                ) : null}
                              </span>
                            </InfoMobileItem>
                          ) : null}

                          {getObjectData && getObjectData.communityTaxes ? (
                            <InfoMobileItem costs={getObjectData.costs}>
                              <span>Kommunale avgifter:</span>
                              <span>
                                {getObjectData.communityTaxes
                                  ? formatCurrency(getObjectData.communityTaxes)
                                  : 0}
                                ,-
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData && getObjectData.monthCosts ? (
                            <InfoMobileItem costs={getObjectData.costs}>
                              <span>Felleskostnader:</span>
                              <span>
                                {getObjectData.monthCosts
                                  ? formatCurrency(getObjectData.monthCosts)
                                  : 0}
                                ,- pr. mnd
                              </span>
                            </InfoMobileItem>
                          ) : null}

                          {getObjectData && getObjectData.commonDebt ? (
                            <InfoMobileItem costs={getObjectData.commonDebt}>
                              <span>Andel fellesgjeld:</span>
                              <span>
                                {formatCurrency(getObjectData.commonDebt)}
                                ,-
                              </span>
                            </InfoMobileItem>
                          ) : null}

                          {getObjectData && getObjectData.collectiveAssets ? (
                            <InfoMobileItem
                              costs={getObjectData && getObjectData.collectiveAssets}
                            >
                              <span>Andel felles formue:</span>
                              <span>
                                {formatCurrency(
                                  getObjectData && getObjectData.collectiveAssets
                                )}
                                ,-
                              </span>
                            </InfoMobileItem>
                          ) : null}

                          {getObjectData && getObjectData.propertyTaxAmount ? (
                            <InfoMobileItem
                              costs={getObjectData && getObjectData.propertyTaxAmount}
                            >
                              <span>Eiendomsskatt:</span>
                              <span>
                                {formatCurrency(getObjectData.propertyTaxAmount)}
                                ,-
                                {getObjectData.propertyTaxYear
                                  ? ` for ${getObjectData.propertyTaxYear}`
                                  : ''}
                              </span>
                            </InfoMobileItem>
                          ) : null}

                          {getObjectData && getObjectData.estimatedValue ? (
                            <InfoMobileItem costs={getObjectData.estimatedValue}>
                              <span>Verditakst:</span>
                              <span>
                                {getObjectData.estimatedValue
                                  ? formatCurrency(getObjectData.estimatedValue)
                                  : 0}
                                ,-
                              </span>
                            </InfoMobileItem>
                          ) : null}

                          {getObjectData && getObjectData.loanFare ? (
                            <InfoMobileItem costs={getObjectData.loanFare}>
                              <span>Lånetakst:</span>
                              <span>
                                {getObjectData.loanFare
                                  ? formatCurrency(getObjectData.loanFare)
                                  : 0}
                                ,-
                              </span>
                            </InfoMobileItem>
                          ) : null}

                          {getObjectData && getObjectData.ownPart ? (
                            <InfoMobileItem costs={getObjectData.ownPart}>
                              <span>Eierbrøk:</span>
                              <span>{getObjectData.ownPart}</span>
                            </InfoMobileItem>
                          ) : null}

                          <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                            <span>Eierform:</span>
                            <span>{getObjectData && getObjectData.ownership}</span>
                          </InfoMobileItem>
                          {this._getMobileSize(getObjectData)}
                          {/*getObjectData &&
                          getObjectData.area &&
                          getObjectData.area.value ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Primærrom:</span>
                              <span>
                                {getObjectData.area.value}m<sup>2</sup>
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData &&
                          getObjectData.area &&
                          getObjectData.area.from ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Primærrom:</span>
                              <span>
                                {getObjectData.area.from}m<sup>2</sup> -{' '}
                                {getObjectData.area.to}m<sup>2</sup>
                              </span>
                            </InfoMobileItem>
                          ) : null*/}

                          {getObjectData &&
                          getObjectData.size &&
                          getObjectData.size.usableExternalArea ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>BRA-E:</span>
                              <span>
                                {getObjectData.size.usableExternalArea}m<sup>2</sup>
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData &&
                          getObjectData.size &&
                          getObjectData.size.enclosedBalconyArea ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>BRA-B:</span>
                              <span>
                                {getObjectData.size.enclosedBalconyArea}m<sup>2</sup>
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData && getObjectData.bra ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Bruksareal:</span>
                              <span>
                                {getObjectData.bra}m<sup>2</sup>
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData && getObjectData.bta ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Bruttoareal:</span>
                              <span>
                                {getObjectData.bta}m<sup>2</sup>
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData &&
                          getObjectData.size &&
                          getObjectData.size.openArea ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>TBA:</span>
                              <span>
                                {getObjectData.size.openArea}m<sup>2</sup>
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData && getObjectData.propertyArea ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Tomt:</span>
                              <span>
                                {getObjectData.propertyArea}m<sup>2</sup>
                                {getObjectData.plotOwned ? '(eiet)' : '(festet)'}
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData && getObjectData.level ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Etasje:</span>
                              <span>{getObjectData.level}.</span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData && getObjectData.rooms ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Rom (soverom):</span>{' '}
                              <span>
                                {getObjectData.rooms} ({getObjectData.bedrooms || 0})
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData && getObjectData.constructionYear ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Byggeår:</span>
                              <span>{getObjectData.constructionYear}</span>
                            </InfoMobileItem>
                          ) : null}
                          <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                            <span>Gårdsnummer:</span>
                            <span>
                              {getObjectData &&
                                getObjectData.matrikkel &&
                                getObjectData.matrikkel.gnr}
                            </span>
                          </InfoMobileItem>
                          <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                            <span>Bruksnummer:</span>
                            <span>
                              {getObjectData &&
                                getObjectData.matrikkel &&
                                getObjectData.matrikkel.bnr}
                            </span>
                          </InfoMobileItem>

                          {getObjectData && getObjectData.energyLabel ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Energimerking:</span>
                              <span>
                                {getObjectData && getObjectData.energyLabelColor
                                  ? this._energyLabelColorLabel(
                                      getObjectData.energyLabelColor
                                    )
                                  : null}{' '}
                                ({getObjectData && getObjectData.energyLabel})
                              </span>
                            </InfoMobileItem>
                          ) : null}
                          {getObjectData && getObjectData.finncode ? (
                            <InfoMobileItem costs={getObjectData && getObjectData.costs}>
                              <span>Finnkode:</span>
                              <a href={`https://finn.no/${getObjectData.finncode}`}>
                                {getObjectData.finncode}
                              </a>
                              <div />
                            </InfoMobileItem>
                          ) : null}
                        </InfoWrapper>
                        <LinkMobileItem
                          label="Flere detaljer"
                          openModal="true"
                          type="sales-text"
                        />
                        <LinkMobileItem
                          label="Digital salgsoppgave"
                          onClick={() => {
                            if (typeof window !== 'undefined') {
                              window.location.href = `https://digitalsalgsoppgave.garanti.no/bestillsalgsoppgave/${getObjectData.estateId}`;
                            }
                          }}
                        />
                        <LinkMobileItem
                          label="Visningspåmelding"
                          openModal="true"
                          estateId={getObjectData && getObjectData.estateId}
                          type="showing"
                        />
                        {getObjectData && getObjectData.bidLink ? (
                          <LinkMobileItem
                            label="Gi bud"
                            onClick={() => {
                              if (typeof window !== 'undefined') {
                                window.open(getObjectData.bidLink, '_blank');
                              }
                            }}
                          />
                        ) : null}
                        <LinkMobileItem
                          label="Verdivurdering"
                          openModal="true"
                          type="valuation"
                          url={getObjectData && getObjectData.valuation}
                        />
                        {getObjectData && getObjectData.smartBoligAnalyse ? (
                          <LinkMobileItem
                            label="Smart Boliganalyse"
                            openModal="true"
                            type="smartbolig"
                          />
                        ) : null}
                        <LinkMobileItem label="Kart" openModal="true" type="map" />
                        <LinkMobileItem
                          label="Nabolagsprofil"
                          openModal="true"
                          type="neighbourhood"
                        />
                        <LinkMobileItem
                          label="Kontakt megler"
                          openModal="true"
                          type="agent"
                        />
                        <LinkMobileItem
                          label="Personvern"
                          onClick={() => {
                            if (typeof window !== 'undefined') {
                              window.location.href = 'https://garanti.no/personvern';
                            }
                          }}
                        />
                      </MobileContent>
                      <Modal sguid={sguid} match={this.props.match} />
                    </Fragment>
                  );
                } else {
                  return null;
                }
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export default compose(
  withApollo,
  graphql(TOGGLE_GALLERY, { name: 'toggleGallery' })
)(ObjectPage);
